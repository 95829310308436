<template>
  <div v-if="items.length" class="lg:container mx-auto overflow-y-scroll h-screen text-black dark:text-gray-200">
    <div class="sm:block lg:flex flex-wrap">
      <div :key="item.id" v-for="item in items" class="sm:w-full lg:w-1/2 my-3">
        <div class="mx-3 p-5 dark:bg-gray-800 bg-white shadow-md dark:shadow-none rounded-md h-full ">
          <h3 class="text-lg mb-2">{{ item.title }}</h3>
          <small class="font-semibold my-4 block">{{ item.date_from }}</small>
          <p class="mb-4" v-html="item.body_formated"></p>
          <router-link class="inline-flex font-semibold detail-link" :to="item.link_normal">Подробнее  <img class="ml-2" src="@/assets/img/icons/arrow.svg" alt="Белтаможсервис"></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ResultList",
  computed: {
    ...mapGetters('search', ['items'])
  },

}

</script>

<style scoped>
::-webkit-scrollbar{
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}
.detail-link:hover img{
  transition: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transform: translateX(10px);
}
</style>