<template>
  <div class="lg:container mx-auto">
    <div class="flex justify-center sm:mx-3 lg:mx-3 py-3  dark:bg-gray-800 bg-white shadow-md dark:shadow-none rounded">
      <input v-model="searchValue" type="text" placeholder="Начните вводить" class="py-3 border-b dark:border-teal-400 border-blue-900
       focus:outline-none sm:w-72 lg:w-96 dark:bg-gray-800 bg-white
      dark:text-white text-black">
      <button class="dark:bg-teal-400 bg-blue-900 text-white py-3 px-6 rounded mx-3" @click="search">Поиск</button>
    </div>
  </div>

</template>

<script>
export default {
  name: "Search",
  data: () => ({
    searchValue: "",
  }),
  created() {
    document.addEventListener('keypress', this.enter)
  },
  destroyed() {
    document.removeEventListener('keypress', this.enter)
  },
  methods: {
    enter(event) {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    search() {
      this.$store.dispatch('search/fetchItems', this.searchValue)
    }
  }
}
</script>

<style scoped>


</style>