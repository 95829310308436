<template>
    <div class="h-full py-24 bg-white dark:bg-gray-900">
        <Search></Search>
        <ResultList class="my-5"></ResultList>
        <Pagination  v-show="display" namespace="search"></Pagination>
    </div>
</template>

<script>
import Search from '../components/search/Search'
import ResultList from '../components/search/ResultList'
import Pagination from '../components/common/Pagination'

export default {
    meta: {
        title: 'Поиск по сайту'
    },
    name: "SearchPage",
    show: false,

    computed: {
        display() {
            return !!this.$store.getters['search/items'].length;
        }
    },
    components: {
        Search, Pagination, ResultList
    }
}
</script>

<style scoped>

</style>